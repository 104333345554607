<template>
    <div class="tab_content">
        <div :class="index==-1?'bg':''" @click="tabBtn(-1)">全部</div>
        <div  :class="index==10?'bg':''" @click="tabBtn(10)">待支付</div>
        <div :class="index==20?'bg':''" @click="tabBtn(20)">待发货</div>
        <div :class="index==30?'bg':''" @click="tabBtn(30)">待收货</div>
        <div :class="index==0?'bg':''" @click="tabBtn(0)">取消/退款</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index:1
        }
    },
    methods: {
        tabBtn(index){
            console.log(index)
            this.index = index;
            this.$emit('tabBtn',index)
        }
    }
    
}
</script>
<style>
    .tab_content{
        display: flex;
        justify-content: space-between;
        font-size: 30px;
        color:#676666;
        margin: 30px 26px;
        position: relative;
        z-index: 9999
    }
    .bg{
        color:#F26117;
        font-size: 34px
    }
</style>