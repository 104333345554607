<template>
  <div class="container_orderList">
    <div class="header_img_o" @click="headerGoods"></div>

    <!-- 搜索 -->
    <div class="share_box_o">
      <input @input="changeBtn" v-model="search" />
    </div>

    <!-- 订单状态 -->
    <order-tab @tabBtn="tabBtn"></order-tab>

    <!-- 订单列表 -->
    <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
      <div style="position:relative;z-index:2">
        <div class="order_list_o" v-for="(item,index) in dataList" :key="index">
          <div class="header_top_o" @click="orderBtn(item.order_id)">
            <div style="display:flex">
              <img src="../../assets/Slice45.png" />
              <div>官方旗舰店</div>
            </div>
            <div style="color:#FF5600">{{item.text}}</div>
          </div>
          <div style="display:flex" @click="orderBtn(item.order_id)">
            <div class="left_img_o">
              <img :src="item.goods_image" />
            </div>
            <div class="goods_info_o">
              <div>{{item.goods_name}}</div>
              <div class="sku">6种口味</div>
              <div>实付：￥{{item.order_amount}}</div>
            </div>
          </div>
          <div class="tabBtn_o">
            <div v-if="item.order_state==10" @click="cloneBtn(item.order_id)">取消订单</div>
            <div v-if="item.order_state==0&&dataList.pay_info" @click="cloneBtn(item.order_id,1)">取消售后</div>
            <div v-if="item.order_state==10" @click="buyOrderBtn(item.order_id)" class="confim_l">确认支付</div>
          </div>
        </div>
      </div>

    </vue-loadmore>
  </div>
</template>
<script>
import orderTab from "../../components/goods/orderTab";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import { 
  cloneReturn_api,
  orderClone_api,
  orderDelete_api,
  bugOrder_api,
  orderList_api
 } from "@/api/deal";
export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10
      },
      dataList: [],
      finished: false,
      search:''
    };
  },
  components: {
    orderTab
  },
  created() {
    document.title = "订单列表";
    this.getList();
  },
  methods: {
    // 跳转福利商城
    headerGoods(){
      this.$router.push("./index")
    },
    // 搜索
    changeBtn(){
      this.dataList = [];
      this.query.page = 1;
      this.query.search = this.search;
      this.getList()
    },
    // 数据列表
    getList() {
      orderList_api(this.query).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.dataList = this.dataList.concat(res.data);
          this.dataList.forEach(e => {
            if (e.order_state == 0) {
              e.text = "取消";
            } else if (e.order_state == 10) {
              e.text = "待付款";
            } else if (e.order_state == 20) {
              e.text = "待发货";
            }else if (e.order_state == 30) {
              e.text = "待收货";
            } else {
              e.text = "已完成";
            }
            if(e.pay_info&&e.order_state==0){
              e.text = "退款";
            }
             if(e.refund_state == 10){
              e.order_state_text = "退款中";
            }else if(e.refund_state == 20){
              e.order_state_text = "退款已完成";
            }else if(e.refund_state ==30){
              e.order_state_text = "拒绝退款";
            }
          });
        }
      });
    },
    cloneOrder() {},
    // 订单状态
    tabBtn(index) {
      this.query.page = 1;
      this.dataList = [];
      this.query.order_state = index;
      if (index == -1) {
        delete this.query.order_state;
      }
      this.getList();
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.fetch();

      done();
    },

    onLoad(done) {
      if (this.query.page <= 10) {
        this.fetch();
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },

    fetch() {
      this.query.page++;
      this.getList();
    },
    // end--------------------------------
    // 订单详情
    orderBtn(id) {
      console.log(id);
      this.$router.push(`./orderDetail?id=${id}`);
    },
    // 取消订单
    cloneBtn(id, status) {
      if (status == 1) {
        let data = {
          type: "cancel "
        };
        cloneReturn_api(this.dataList.order_id, data).then(res => {
          if (res.code == 0) {
            this.$toast("操作成功");
            this.$router.go(-1);
          }else{
            this.$toast(res.error);
          }
        });
      } else {
        orderClone_api(id).then(res => {
          if (res.code == 0) {
            this.$toast("操作成功");
            this.$router.go(-1);
          }else{
            this.$toast(res.error);
          }
        });
      }
    },
    // 删除订单
    deleteBtn(id) {
      orderDelete_api(id).then(res => {
        if (res.code == 0) {
          this.$toast("操作成功");
          this.$router.go(-1);
        }
      });
    },
    // 立即支付
    buyOrderBtn(id) {
      bugOrder_api({ order_id: id }).then(res => {
        if (res.code == 0) {
          this.wxPay(res)
        }
      });
    },
    async wxPay(res) {
      let that = this;
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let res1 = await apiCommon.getConfig();
      console.log(res1);
      let tableData = res1.data;
      wx.config(tableData);
      wx.chooseWXPay({
        timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.pay_info.paySign, // 支付签名
        success(res) {
          if (res.errMsg == "chooseWXPay:ok") {
            that.$toast("支付成功");
            that.query.page = 1;
            that.dataList = [];
            that.getList()
          } else {
            alert(res.errMsg);
            that.$toast("支付失败", "middle");
          }
        },
        cancel: function(res) {
          that.$toast("支付取消", "middle", res);
        }
      });
    },
  }
};
</script>
<style scoped>
.container_orderList {
  width: 100%;
  background-color: #f2f2f2;
  padding-top: 30px;
}
.header_img_o {
  width: 700px;
  height: 350px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 0 auto 30px;
  background-image: url("../../assets/order.png");
  background-size: 100% 100%;
}

.share_box_o input {
  width: 650px;
  height: 70px;
  background: #e1e1e1;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: none;
  margin: 30px 50px;
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 30px;
}
.share_box_o {
}
.order_list_o {
  width: 700px;
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 30px auto 30px;
  padding: 25px;
  box-sizing: border-box;
}

.header_top_o {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  color: #676666;
}

.header_top_o img {
  width: 33px;
  height: 33px;
  margin-right: 10px;
}
.left_img_o {
  width: 224px;
  height: 224px;
  background-color: pink;
  margin: 25px 25px 0 0;
}
.left_img_o img {
  width: 100%;
  height: 100%;
}
.tabBtn_o div {
  width: 160px;
  height: 60px;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 1px solid #b4b4b4;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  margin-left: 50px;
}
.tabBtn_o {
  display: flex;
  margin-left: 300px;
}
.goods_info_o {
  font-size: 30px;
  width: 380px;
  margin-top: 25px;
}
.sku {
  font-size: 26px;
  color: #676666;
  margin: 10px 0 45px 0;
}
.confim_l {
  width: 160px;
  height: 60px;
  background: #ffe4d0;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 1px solid #ff5600;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  color: #ff5600;
}
.vuejs-refresh-head {
  margin-top: 100px;
}
</style>
